import api from '../../services';
import { useSnackbar } from 'notistack';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../../Components/UI/Table/Table';
import EditOffIcon from '@mui/icons-material/EditOff';
import Button from '../../Components/UI/Button/Button';
import ErrorBoundary from '../../Errors/ErrorBoundary';
import Loader from '../../Components/UI/Loader/Loader';
import PromptSnackbar from '../../Components/UI/Snackbar/PromptSnackbar';

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import ButtonComp from '../../Components/UI/Button/Button';

const SESSIONS = [
  'May/June P11',
  'May/June P12',
  'May/June P13',
  'May/June P21',
  'May/June P22',
  'May/June P23',
  'Oct/Nov P11',
  'Oct/Nov P12',
  'Oct/Nov P13',
  'Oct/Nov P21',
  'Oct/Nov P22',
  'Oct/Nov P23',
];

const PastPaperRef = () => {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    year: '',
    session: [],
  });
  const [paperArr, setPaperArr] = useState([]);
  const [errors, setErrors] = useState({
    year: false,
    session: false,
  });
  const [allPpaerReferences, setAllPaperReferences] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [paperRefId, setPaperRefId] = useState(null);
  const [year, setYear] = useState(null);
  const [deleteSubject, setDeleteSubject] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const isTable = true;

  const getAllPaperReferences = async () => {
    let temp = await api.get.getPaperReference(isTable);
    setAllPaperReferences(temp);
    setDataIsLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (newState) => {
    setDeleteSubject({ open: true, ...newState });
  };

  const fields = [
    { field: 'id' },
    { field: 'session', headerName: 'Past Paper References Session', flex: 1 },
    { field: 'year', headerName: 'Past Paper References Year', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              setSelectedCategory(params);
              setPaperRefId(params?.row?.id);
              setData({
                session: params?.row?.session,
                year: params?.row?.year?.toString(),
              });
              setPaperArr([params?.row?.session]);
              setYear(params?.row?.year?.toString());
              setShowForm(true);
            }}
          >
            <EditOffIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            style={{ color: 'red' }}
            onClick={(e) => {
              handleClick({
                vertical: 'top',
                horizontal: 'center',
              });
              setSelectedCategory(params);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleSubmit = () => {
    let hasError = false;

    if (!data?.session) {
      setErrors((prev) => ({ ...prev, session: true }));
      hasError = true;
    }

    if (!year) {
      setErrors((prev) => ({ ...prev, year: true }));
      hasError = true;
    }

    if (!hasError) {
      // Clear data and errors

      setErrors({
        session: false,
        year: false,
      });

      if (typeof year !== 'string') {
        const payload = {
          session: data?.session,
          year: year?.getFullYear(),
        };

        addEditPaperRef(payload);
      }
    }
  };

  // Add or Update the Paper Reference
  const addEditPaperRef = async (payload) => {
    setLoading(true);

    const result = await api.post
      .addPaperReference(payload, paperRefId)
      .then((res) => {
        if (res?.data) {
          const { error } = res;

          if (!error) {
            enqueueSnackbar(
              `Past Paper Reference ${
                paperRefId ? 'updated' : 'created'
              } successfully!`,
              {
                variant: 'success',
                autoHideDuration: 3000,
              }
            );

            setLoading(false);
            setShowForm(false);
            setPaperArr([]);
          } else {
            enqueueSnackbar(
              `OOPS! error occoured while ${
                paperRefId ? 'updating' : 'creating'
              } Past Paper Reference, Please try again`,
              {
                variant: 'error',
                autoHideDuration: 4000,
              }
            );
          }

          getAllPaperReferences();
        } else {
          enqueueSnackbar(res?.response, {
            variant: 'error',
            autoHideDuration: 4000,
          });

          setLoading(false);
        }
      });
  };

  const deleteTagHandler = async () => {
    const result = await api.delete.deletePaperReference(
      selectedCategory.row.id
    );
    const { error } = result;
    if (!error) {
      enqueueSnackbar('Past Paper Reference deleted successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(
        'OOPS! error occoured while deleting Past Paper Reference, Please try again',
        {
          variant: 'error',
          autoHideDuration: 4000,
        }
      );
    }
    getAllPaperReferences();
    setDeleteSubject({ ...deleteSubject, open: false });
  };

  const handlePaperRef = (event) => {
    const { value } = event.target;

    setPaperArr(value);

    setData((prev) => ({
      ...prev,
      session: value,
    }));

    setErrors((prev) => {
      return {
        ...prev,
        session: false,
      };
    });
  };

  useEffect(() => {
    setDataIsLoading(true);
    getAllPaperReferences();
  }, []);

  return (
    <React.Fragment>
      <Button
        onClick={(e) => {
          setShowForm(!showForm);
          setData(null);
          setYear(null);
          setPaperRefId(null);
        }}
        style={{ width: '100%', marginBottom: '23px' }}
      >
        {showForm ? 'Show Past Paper Reference' : 'Add Past Paper Reference'}
      </Button>

      {showForm ? (
        <>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <FormControl>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Select Year *
              </Typography>

              <DatePicker
                onChange={(value) => {
                  setYear(value);

                  setErrors((prev) => {
                    return {
                      ...prev,
                      year: false,
                    };
                  });
                }}
                value={year}
                maxDetail="decade"
                format="yyyy"
                yearPlaceholder="Select Year"
                maxDate={new Date()}
                clearIcon={false}
              />

              {errors?.year && (
                <Typography
                  variant="small"
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Year is required
                </Typography>
              )}
            </FormControl>

            <FormControl>
              <InputLabel id="demo-multiple-name-label">
                Add Paper Reference *
              </InputLabel>

              <Select
                multiple={paperRefId ? false : true}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={paperRefId ? data?.session : paperArr}
                onChange={handlePaperRef}
                input={<OutlinedInput label="Add Paper Reference" />}
              >
                {dataIsLoading ? (
                  <Loader />
                ) : (
                  SESSIONS?.map((paperref, index) => (
                    <MenuItem key={index} value={paperref}>
                      {paperref}
                    </MenuItem>
                  ))
                )}
              </Select>

              {errors?.session && (
                <Typography
                  variant="small"
                  style={{
                    color: 'red',
                    fontSize: '12px',
                  }}
                >
                  Session is required
                </Typography>
              )}
            </FormControl>

            {loading ? (
              <Loader />
            ) : (
              <ButtonComp onClick={handleSubmit}>
                {paperRefId ? 'Update' : 'Add'} Paper Reference
              </ButtonComp>
            )}
          </Box>
        </>
      ) : (
        <>
          <ErrorBoundary>
            {dataIsLoading ? (
              <Loader />
            ) : (
              <Table tableData={allPpaerReferences} cols={fields} />
            )}
          </ErrorBoundary>

          <PromptSnackbar
            setState={setDeleteSubject}
            state={deleteSubject}
            clickHandler={deleteTagHandler}
          >
            Do you want to Delete this Past Paper Reference
          </PromptSnackbar>
        </>
      )}
    </React.Fragment>
  );
};

export default PastPaperRef;
