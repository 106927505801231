import React from "react";
import ConfigureSimulations from "./ConfigureSimulations";

const AddSimulationAccordion = ({ selected, currentCourdId }) => {
  return (
    <>
      <ConfigureSimulations selected={selected} />,
    </>
  );
};

export default AddSimulationAccordion;
