const extractNumber = (order) => {
  if (order != null) {
    const match = order.match(/\d+/);
    return parseInt(match[0]);
  } else {
    return -1;
  }
};

export { extractNumber };
