import React, { useState, useEffect, useContext } from "react";
import FlashcardPair from "./FlashcardPair";
import FlashcardList from "./FlashcardList";
import isEmptyObj from "../../../helpers/isEmptyObj";
import api from "../../../services";
import { ADD_CONTENT_CONTEXT } from "../../../Context/AddContent";
import { Loader } from "../../../helpers/Loader";
import { extractNumber } from "../../../helpers/extractNumbers";

const ConfigureFlashcards = ({ selected, tag }) => {
  // This logic of checking whether the code is empty or not could be set in the flashcard pair component, and tbh it should be, i realized later. (Dont change it right now, we will change it later)
  const [flashcards, setFlashcards] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [answerIsEmptyError, setAnswerIsEmptyError] = useState(false);
  // const [questionIsEmptyError, setQuestionIsEmptyError] = useState(false);
  const [resetVar, setResetVar] = useState("");
  const [currentFlashCardId, setCurrentFlashCardId] = useState(0);
  const { currentSelected } = useContext(ADD_CONTENT_CONTEXT);
  const [updateOrder, setUpdateOrder] = useState(false);
  // console.log(currentSelected);
  const [error, setError] = React.useState({
    value: false,
    message: "",
  });

  const getSelectedFlashcardId = () => {
    let tempSelection = selected;
    getFlashcardsHandler(tempSelection)
      .then((el) => {
        if (!el.data.error) {
          // let data = el?.data?.questions;
          const data1 = Object.values(el.data.questions)[0];
          // const { 3: data1 } = el?.data.questions;
          // console.log("questions", data1);
          // console.log("data", data);
          // console.log("tag id", tag.id);
          const filteredNotes = data1.filter((el) => el.tagId === tag.id);

          filteredNotes.map((el, index) => {
            setCurrentFlashCardId(el.id);
          });
          setLoading(false);
          return true;
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getSelectedFlashcardId();
  }, [tag]);

  const getFlashcardsHandler = async () => {
    let temp = await api.get.getFlashcardsByTagAndChapter({
      tagId: tag.id,
      chapterId: currentSelected.chapId,
    });
    return temp;
  };

  const appendFlashcardHandler = (data) => {
    const copyFlashcardsByTag = data.filter(
      (el) =>
        el.tagId === tag.id &&
        el.snackId == currentSelected.id &&
        el.chapterId == currentSelected.chapId
    );

    const sortedFlashcards = copyFlashcardsByTag.sort((a, b) => {
      const numA = extractNumber(a.order);
      const numB = extractNumber(b.order);

      // If either card doesn't have an order, sort by id
      if (numA === -1 && numB === -1) {
        return a.id - b.id;
      } else if (numA === -1) {
        return 1; // Place card without order after
      } else if (numB === -1) {
        return -1; // Place card without order before
      } else {
        return numA - numB; // Sort by order
      }
    });
    let someUndefindeOrders = sortedFlashcards.some(
      (item) => item.order == undefined
    );

    setUpdateOrder(someUndefindeOrders);
    setFlashcards(sortedFlashcards);
    copyFlashcardsByTag.map((item) => {
      setCurrentFlashCardId(item.id);
    });

    return true;
  };

  const getFlashcardTrigger = () => {
    let tempSelection = selected;
    getFlashcardsHandler(tempSelection)
      .then((el) => {
        if (!el.data.error) {
          // const data1 = Object.values(el.data.questions)[0];
          // console.log("flashcardArray", flashcardArray);
          const data1 = el?.data;
          // console.log("data1", data1);
          appendFlashcardHandler(data1);
          return true;
        }
      })
      .catch((err) => console.error(err));
    return true;
  };

  React.useEffect(() => {
    getFlashcardTrigger();
  }, [selected]);

  // Send data to flashcardList as props
  const getQuestionAnswerPairHandler = (e) => {
    const hasEmptyValues = e.isEmpty;
    if (hasEmptyValues) {
      // should not execute further if values are empty
      // display error message
      setError({
        value: true,
        message: "Please add a front side and a back side respectively",
      });
      return;
    } else {
      // set error false
      setError({
        value: false,
        message: "",
      });
      return sendFlashcardToDBHandler(e);
    }
  };

  const addToChapterHandler = async ({ payload, selectionId, tagId }) => {
    let temp = await api.post.createCourse.addFlashCardWithChapter({
      e: payload,
      selectedChapterId: selectionId,
      tagIdState: tagId,
    });

    return temp;
  };

  const addToSnackHandler = async ({ payload, selectionId, tagId }) => {
    let temp = await api.post.createCourse.addFlashCardWithSnacks({
      e: payload,
      selectedSnackId: selectionId,
      tagIdState: tagId,
    });
    return temp;
  };

  const sendFlashcardToDBHandler = (data) => {
    console.log("data: ", data);
    setLoading(true);
    const payload = data.data;
    const selectionCriteria = data.props;
    let tempTag = tag;
    if (selectionCriteria.selected.type === "chapter") {
      addToChapterHandler({
        payload,
        selectionId: selectionCriteria.selected.id,
        tagId: tempTag.id,
      })
        .then((el) => {
          getFlashcardTrigger();
          setLoading(false);
          return true;
        })
        .catch((err) => {
          getFlashcardTrigger();
          setLoading(false);
          return false;
        });
    }
    if (selectionCriteria.selected.type === "snack") {
      addToSnackHandler({
        payload,
        selectionId: selectionCriteria.selected.id,
        tagId: tempTag.id,
      })
        .then((el) => {
          getFlashcardTrigger();
          setLoading(false);
          return true;
        })
        .catch((err) => {
          getFlashcardTrigger();
          console.error(err);
          setLoading(false);
          return false;
        });
    }
  };

  const flashcardPairProps = {
    sideEffects: getQuestionAnswerPairHandler,
    selected,
    resetTrigger: resetVar,
    error,
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <FlashcardList
            updateOrder={updateOrder}
            data={flashcards}
            tag={tag}
            selected={selected}
            setFlashcards={setFlashcards}
            currentFlashCardId={currentFlashCardId}
          />
          <FlashcardPair {...flashcardPairProps} data={flashcards} tag={tag} />
        </>
      )}
    </div>
  );
};

export default ConfigureFlashcards;
