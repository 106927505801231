import Papa from 'papaparse';
import api from '../../../services';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import { Upload } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Close from '../../../assets/img/svgs/Close.svg';
import Table from '../../../Components/UI/Table/Table';
import OutlinedInput from '@mui/material/OutlinedInput';
import ErrorBoundary from '../../../Errors/ErrorBoundary';
import Loader from '../../../Components/UI/Loader/Loader';
import ButtonComp from '../../../Components/UI/Button/Button';
import { ClsoeIcon, Image, ImgBox } from './FlashcardPair.style';
import PromptSnackbar from '../../../Components/UI/Snackbar/PromptSnackbar';
import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

const ConfigurePastQuestions = ({ selected, unitId }) => {
  const [data, setData] = useState({
    text: '',
    imgURL: '',
    correctAnswer: 'A',
    pastPaperReferenceId: '',
  });

  const [csvData, setCsvData] = useState([]);
  const [bulkQuestions, setBulkQuestions] = useState([]);

  const [csvErrors, setCsvErrors] = useState({
    imgs: '',
    file: '',
  });

  const [csvFile, setCsvFile] = useState('');
  console.log('csvFile: ', csvFile);

  const [errors, setErrors] = useState({
    imgURL: false,
    correctAnswer: false,
    pastPaperReferenceId: false,
  });

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [questionType, setQuestionType] = useState({
    singleQuestion: false,
    bulkQuestions: false,
  });

  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [allQuestions, setAllQuestions] = useState([
    {
      id: 1,
      questionNo: 1,
      correctAns: 'helo12',
      questionImage:
        'https://octilearn-prod.s3.us-west-1.amazonaws.com/users/1709882230920.png',
      reference: '2054',
    },
  ]);
  const [allPaperRef, setAllPaperRef] = useState([]);
  const [paperRef, setPaperRef] = useState();
  const [loading, setLoading] = useState(false);
  const [questionImg, setQuestionImg] = useState(null);
  const [deleteSubject, setDeleteSubject] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [quizQuestionId, setQuizQuestionId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const imageHandler = (e) => {
    const file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setData((prev) => {
        return {
          ...prev,
          imgURL: fileReader.result,
        };
      });
    };

    setErrors({ ...errors, imgURL: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear error when user starts typing
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  // Submit single question
  const handleSubmit = () => {
    let hasError = false;
    const newErrors = {};

    // Check for empty fields

    Object.keys(data).forEach((key) => {
      if (key !== 'text') {
        if (!data[key]) {
          newErrors[key] = true;
          hasError = true;
        }
      }
    });

    if (hasError) {
      setErrors(newErrors);
    } else {
      // Clear data and errors

      setErrors({
        imgURL: false,
        correctAnswer: false,
        pastPaperReferenceId: false,
      });

      const payload = {
        ...data,
        unitId,
        chapterId: selected?.chapId,
        snackId: selected?.id,
      };

      addEditQuizQuestion(payload);
      setData({
        text: '',
        imgURL: '',
        correctAnswer: 'A',
        pastPaperReferenceId: '',
      });
      setQuizQuestionId(null);
      setLoading(true);
    }
  };

  const handleBulkSubmit = () => {
    let correctAnswer = false;
    let pastPaperRef = false;

    for (const item of bulkQuestions) {
      if (!item.correctAnswer) {
        correctAnswer = true;
      }

      if (!item.pastPaperReferenceId) {
        pastPaperRef = true;
      }

      if (correctAnswer || pastPaperRef) {
        break;
      }
    }

    if (correctAnswer || pastPaperRef) {
      const message = correctAnswer
        ? 'Please provide the correct answer for all questions'
        : 'Please provide the past paper reference for all questions';

      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setLoading(true);
      addEditBulkQuizQuestions(bulkQuestions);
    }
  };

  // Create || Update  Quiz Question

  const addEditQuizQuestion = async (payload) => {
    const result = await api.post.createQuizQuestion(payload, quizQuestionId);

    if (result?.error) {
      setLoading(false);
      setShowForm(false);
      setQuestionType((prev) => {
        return {
          ...prev,
          singleQuestion: false,
        };
      });

      enqueueSnackbar(
        `OOPS! error occoured while ${
          quizQuestionId ? 'updating' : 'creating'
        } a quiz question, Please try again`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      setLoading(false);
      setShowForm(false);
      setQuestionType((prev) => {
        return {
          ...prev,
          singleQuestion: false,
        };
      });

      enqueueSnackbar(
        `Quiz Question has been ${
          quizQuestionId ? 'updated' : 'created'
        } successfully!`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      );

      getAllQuizQuestions();
    }
  };

  // Create bulk Quiz Questions

  const addEditBulkQuizQuestions = async (payload) => {
    const result = await api.post.createBulkQuestions(payload);

    if (result?.error) {
      setLoading(false);
      setShowForm(false);
      setQuestionType((prev) => {
        return {
          ...prev,
          bulkQuestions: false,
        };
      });

      enqueueSnackbar(
        `OOPS! error occoured while ${
          quizQuestionId ? 'updating' : 'creating'
        } a bulk quiz questions, Please try again`,
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      setLoading(false);
      setShowForm(false);
      setQuestionType((prev) => {
        return {
          ...prev,
          bulkQuestions: false,
        };
      });

      enqueueSnackbar(
        `Quiz Questions has been ${
          quizQuestionId ? 'updated' : 'created'
        } successfully!`,
        {
          variant: 'success',
          autoHideDuration: 3000,
        }
      );

      getAllQuizQuestions();
    }
  };

  // Get Quiz Questions
  const getAllQuizQuestions = async () => {
    const isTable = true;
    const temp = await api.get.getQuizQuestions({
      isTable,
      snackId: selected?.id,
    });
    setDataIsLoading(false);
    setAllQuestions(temp);
  };

  // Delete a Quiz Question

  const deleteQuizQuestion = async () => {
    const result = await api.delete.deleteQuestion(quizQuestionId);

    if (result?.error) {
      enqueueSnackbar(
        'OOPS! error occoured while deleting a quiz question, Please try again',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
    } else {
      getAllQuizQuestions();

      setDeleteSubject({ ...deleteSubject, open: false });

      enqueueSnackbar('Quiz Question has been deleted successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  };

  const handleTags = (event) => {
    let {
      target: { value },
    } = event;

    setData((prev) => {
      return {
        ...prev,
        pastPaperReferenceId: quizQuestionId ? value : value?.id,
      };
    });

    setPaperRef(quizQuestionId ? value : value?.text);

    setErrors({ ...errors, pastPaperReferenceId: false });
  };

  const handleClick = (newState) => {
    setDeleteSubject({ open: true, ...newState });
  };

  const handleCorrectAnswerChange = (event) => {
    setData({ ...data, correctAnswer: event.target.value });
  };

  const fields = [
    { field: 'id' },
    { field: 'text', headerName: 'Text' },
    { field: 'correctAnswer', headerName: 'Correct Answer' },
    {
      field: 'publicKey',
      headerName: 'Question Image',
      height: 80,
      width: 100,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="img"
          style={{
            minWidth: 100,
            height: 80,
            objectFit: 'cover',
          }}
        />
      ),
    },

    {
      field: 'pastPaperReferenceText',
      headerName: 'Past Paper Reference',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => {
              setQuizQuestionId(params?.row?.id);

              setData({
                text: params?.row?.text,
                imgURL: params?.row?.publicKey,
                correctAnswer: params?.row?.correctAnswer,
                pastPaperReferenceId: params?.row?.pastPaperReferenceId,
              });

              setPaperRef(params?.row?.pastPaperReferenceId);

              setShowForm(true);
            }}
          >
            <EditOffIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            style={{ color: 'red' }}
            onClick={() => {
              handleClick({
                vertical: 'top',
                horizontal: 'center',
              });

              setQuizQuestionId(params?.row?.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // Get Past Paper References

  const getAllPaperReferences = async () => {
    const isTable = false;
    const temp = await api.get.getPaperReference(isTable);
    setAllPaperRef(temp);
    setDataIsLoading(false);
  };

  const handleClose = () => {
    setQuestionImg(null);
  };

  const allowedExtensions = ['csv'];

  const handleCSVFile = (e) => {
    setCsvErrors((prev) => {
      return {
        ...prev,
        imgs: false,
      };
    });

    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setCsvErrors((prev) => {
          return {
            ...prev,
            imgs: 'Please input a csv file',
          };
        });
        return;
      }

      setCsvFile(inputFile);

      setCsvErrors((prev) => {
        return {
          ...prev,
          file: false,
        };
      });
    }
  };

  const handleParse = () => {
    if (imageURLS.length < 1) {
      return setCsvErrors((prev) => {
        return {
          ...prev,
          imgs: 'Please upload question images',
        };
      });
    }

    if (!csvFile) {
      return setCsvErrors((prev) => {
        return {
          ...prev,
          file: 'Please upload a CSV file',
        };
      });
    }

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        skipEmptyLines: true,
      });
      const parsedData = csv?.data;
      const rows = Object.values(parsedData);

      setCsvData(rows);
    };
    reader.readAsText(csvFile);
  };

  useEffect(() => {
    setDataIsLoading(true);
    getAllPaperReferences();
    getAllQuizQuestions();
  }, [selected]);

  useEffect(() => {
    if (images.length < 1) return;

    Promise.all(
      images.map(
        (item) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(item);

            fileReader.onload = () => {
              resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };
          })
      )
    )
      .then((newImageUrls) => {
        setImageURLs(newImageUrls);
      })
      .catch((error) => {
        console.error('Error reading file:', error);
      });
  }, [images]);

  function onImageChange(e) {
    setImages((prev) => {
      const newImages = [...prev, ...e.target.files];
      return newImages;
    });
    setCsvErrors((prev) => {
      return {
        ...prev,
        imgs: '',
      };
    });
  }

  useEffect(() => {
    // remove empty fields from CSV File
    const clearnData = csvData
      .map((obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(
            ([key, value]) => key !== '' && value !== ''
          )
        );
      })
      ?.filter((obj) => {
        return Object.keys(obj).length > 0;
      });

    if (csvData.length > 0 && clearnData?.length !== imageURLS?.length) {
      window.alert('Invalid Data');
      return;
    }

    // Add other fields
    clearnData &&
      clearnData.map((item, index) => {
        return Object.assign(item, {
          imgURL: imageURLS[index],
          unitId,
          chapterId: selected?.chapId,
          snackId: selected?.id,
        });
      });

    // Update keys
    const CSVData = clearnData?.map((obj) => {
      const updatedObj = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (key === '_1') {
          updatedObj['correctAnswer'] = value;
        } else if (key === '_2') {
          updatedObj['pastPaperReferenceId'] = value;
        } else {
          updatedObj[key] = value;
        }
      });
      return updatedObj;
    });

    setBulkQuestions(CSVData);
  }, [csvData]);

  return (
    <>
      <div
        style={{
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        {quizQuestionId && showForm === true ? (
          <ButtonComp
            style={{ width: '100%' }}
            onClick={() => {
              setShowForm(!showForm);
              setQuizQuestionId(null);
            }}
          >
            Go Back
          </ButtonComp>
        ) : (
          <>
            {questionType?.bulkQuestions === true && showForm === true ? (
              ''
            ) : (
              <ButtonComp
                style={{ width: '100%' }}
                onClick={() => {
                  setShowForm(!showForm);

                  setQuestionType((prev) => {
                    return {
                      ...prev,
                      singleQuestion: !prev.singleQuestion,
                    };
                  });
                  setData({
                    text: '',
                    imgURL: '',
                    correctAnswer: 'A',
                    pastPaperReferenceId: '',
                  });

                  setPaperRef();
                  setQuizQuestionId(null);
                }}
              >
                {questionType?.singleQuestion ? 'Go Back' : ' Add Question'}
              </ButtonComp>
            )}

            {questionType?.singleQuestion === true && showForm === true ? (
              ''
            ) : (
              <ButtonComp
                style={{ width: '100%' }}
                onClick={() => {
                  setShowForm(!showForm);
                  setQuestionType((prev) => {
                    return {
                      ...prev,
                      bulkQuestions: !prev.bulkQuestions,
                    };
                  });
                  setImageURLs([]);
                  setImages([]);
                  setCsvFile('');
                  setBulkQuestions([]);
                  setCsvErrors({ imgs: '', file: '' });
                }}
              >
                {questionType?.bulkQuestions ? 'Go Back' : 'Add bulk questions'}
              </ButtonComp>
            )}
          </>
        )}
      </div>

      {showForm ? (
        <>
          {questionType?.bulkQuestions ? (
            <>
              {bulkQuestions?.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  {bulkQuestions?.map((item, index) => {
                    return (
                      <>
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <img
                            src={item.imgURL}
                            alt=""
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>

                        <FormControl>
                          <InputLabel id="demo-multiple-name-label">
                            Past Paper Reference *
                          </InputLabel>

                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={item?.pastPaperReferenceId}
                            onChange={(e) => {
                              let {
                                target: { value },
                              } = e;

                              const updated = [...bulkQuestions];

                              updated[index] = {
                                ...item,
                                pastPaperReferenceId: value,
                              };

                              setBulkQuestions(updated);
                            }}
                            input={
                              <OutlinedInput label="Add Paper Reference" />
                            }
                          >
                            {dataIsLoading ? (
                              <Loader />
                            ) : (
                              allPaperRef?.map((paperref, index) => {
                                const paperRef = `${
                                  paperref?.session?.split(' ')[0]
                                } ${paperref?.year} ${
                                  paperref?.session?.split(' ')[1]
                                }`;

                                return (
                                  <MenuItem key={index} value={paperRef}>
                                    {`${paperref?.session?.split(' ')[0]} ${
                                      paperref?.year
                                    } ${paperref?.session?.split(' ')[1]}`}
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                        </FormControl>

                        <RadioGroup
                          aria-label="correct-answer"
                          name="correct-answer"
                          value={item.correctAnswer}
                          style={{ display: 'flex', flexDirection: 'row' }}
                          onChange={(e) => {
                            const updated = [...bulkQuestions];

                            updated[index] = {
                              ...item,
                              correctAnswer: e.target.value,
                            };

                            setBulkQuestions(updated);
                          }}
                        >
                          <FormControlLabel
                            value="A"
                            control={<Radio />}
                            label="A"
                            name="correctAnswer"
                          />

                          <FormControlLabel
                            value="B"
                            control={<Radio />}
                            label="B"
                            name="correctAnswer"
                          />

                          <FormControlLabel
                            value="C"
                            control={<Radio />}
                            label="C"
                            name="correctAnswer"
                          />

                          <FormControlLabel
                            value="D"
                            control={<Radio />}
                            label="D"
                            name="correctAnswer"
                          />
                        </RadioGroup>
                      </>
                    );
                  })}

                  {loading ? (
                    <Loader />
                  ) : (
                    <ButtonComp onClick={handleBulkSubmit}>
                      Add Bulk Questions
                    </ButtonComp>
                  )}
                </div>
              ) : (
                <div>
                  <FormControl
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                      }}
                    >
                      <input
                        type="file"
                        multiple
                        id="img"
                        accept="image/*"
                        onChange={onImageChange}
                        style={{ display: 'none' }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                            cursor: 'pointer',
                            flexDirection: 'column',
                          }}
                        >
                          <label
                            htmlFor="img"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Upload></Upload>{' '}
                            {images?.length > 0 ? 'Update' : 'Upload'} Questions
                            Images{' '}
                            <span
                              style={{
                                color: 'red',
                              }}
                            >
                              *
                            </span>
                          </label>

                          <span
                            style={{
                              fontSize: '12px',
                              color: 'red',
                            }}
                          >
                            {csvErrors.imgs}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '20px',
                          justifyContent: 'center',
                        }}
                      >
                        {imageURLS.map((imageSrc) => (
                          <img src={imageSrc} alt="not fount" width={'100px'} />
                        ))}
                      </div>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <label
                        htmlFor="csvInput"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Upload></Upload> {csvFile ? 'Update' : 'Upload'} CSV
                        File{' '}
                        <span
                          style={{
                            color: 'red',
                          }}
                        >
                          *
                        </span>
                      </label>

                      <input
                        onChange={handleCSVFile}
                        id="csvInput"
                        name="file"
                        type="File"
                        style={{ display: 'none' }}
                      />

                      {csvFile ? (
                        csvFile?.name
                      ) : (
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'red',
                          }}
                        >
                          {csvErrors.file}
                        </span>
                      )}
                    </Box>

                    <div>
                      <ButtonComp
                        style={{ width: '100%' }}
                        onClick={handleParse}
                      >
                        Preview Questions
                      </ButtonComp>
                    </div>
                  </FormControl>
                </div>
              )}
            </>
          ) : (
            <>
              <FormControl
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '20' }}>
                  Question {quizQuestionId ? 'ID' : 'No'} :{' '}
                  {quizQuestionId ? quizQuestionId : allQuestions.length + 1}
                </Typography>

                <TextField
                  label="Add Text"
                  onChange={handleChange}
                  name="text"
                  value={data.text}
                />

                <Box>
                  <Typography variant="body1">
                    Select Correct Answer *
                  </Typography>

                  <RadioGroup
                    aria-label="correct-answer"
                    name="correct-answer"
                    value={data.correctAnswer}
                    style={{ display: 'flex', flexDirection: 'row' }}
                    onChange={handleCorrectAnswerChange}
                  >
                    <FormControlLabel
                      value="A"
                      control={<Radio />}
                      label="A"
                      name="correctAnswer"
                    />

                    <FormControlLabel
                      value="B"
                      control={<Radio />}
                      label="B"
                      name="correctAnswer"
                    />

                    <FormControlLabel
                      value="C"
                      control={<Radio />}
                      label="C"
                      name="correctAnswer"
                    />

                    <FormControlLabel
                      value="D"
                      control={<Radio />}
                      label="D"
                      name="correctAnswer"
                    />
                  </RadioGroup>
                </Box>

                <FormControl>
                  <InputLabel
                    id="demo-multiple-name-label"
                    sx={{
                      color: errors?.pastPaperReferenceId ? '#D32f2f' : '',
                    }}
                  >
                    Add Paper Reference *
                  </InputLabel>

                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={paperRef}
                    onChange={handleTags}
                    input={<OutlinedInput label="Add Paper Reference" />}
                    error={errors.pastPaperReferenceId}
                    helperText={
                      errors.pastPaperReferenceId
                        ? 'Paper reference  is required'
                        : ''
                    }
                  >
                    {dataIsLoading ? (
                      <Loader />
                    ) : (
                      allPaperRef?.map((paperref, index) => (
                        <MenuItem
                          key={index}
                          value={quizQuestionId ? paperref?.id : paperref}
                        >
                          {`${paperref?.session?.split(' ')[0]} ${
                            paperref?.year
                          } ${paperref?.session?.split(' ')[1]}`}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                <Box
                  sx={{
                    paddingY: '15px',
                    paddingX: '5px',
                  }}
                >
                  <input
                    onChange={imageHandler}
                    id="img"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                  />

                  {data?.imgURL && (
                    <img
                      src={data?.imgURL}
                      alt="img"
                      style={{ height: 100, width: 100, borderRadius: 20 }}
                    />
                  )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      style={{ display: 'flex', gap: 10, cursor: 'pointer' }}
                    >
                      <label htmlFor="img">
                        <Upload></Upload>{' '}
                        {data?.imgURL
                          ? 'Update Question image *'
                          : 'Upload Question Image *'}{' '}
                      </label>
                    </div>

                    {errors?.imgURL && (
                      <p
                        style={{
                          color: '#D32f2f',
                          fontSize: 12,
                          marginLeft: 14,
                        }}
                      >
                        Image is required
                      </p>
                    )}
                  </div>
                </Box>

                {loading ? (
                  <Loader />
                ) : (
                  <ButtonComp onClick={handleSubmit}>
                    {quizQuestionId ? 'Update' : 'Add'} Question
                  </ButtonComp>
                )}
              </FormControl>
            </>
          )}
        </>
      ) : (
        <>
          <ErrorBoundary>
            {dataIsLoading ? (
              <Loader />
            ) : (
              <Table
                tableData={allQuestions}
                cols={fields}
                setQuestionImg={setQuestionImg}
              />
            )}
          </ErrorBoundary>

          <PromptSnackbar
            setState={setDeleteSubject}
            state={deleteSubject}
            clickHandler={deleteQuizQuestion}
          >
            Do you want to Delete this Quiz questions
          </PromptSnackbar>
        </>
      )}

      {questionImg && (
        <Modal
          open={questionImg}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Fade in={questionImg}>
            <ImgBox>
              <ClsoeIcon onClick={handleClose}>
                <img src={Close} alt="close" />
              </ClsoeIcon>

              <Image src={questionImg} alt="img" />
            </ImgBox>
          </Fade>
        </Modal>
      )}
    </>
  );
};

export default ConfigurePastQuestions;
