import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import ButtonComp from "./../../Components/UI/Button/Button";
import Typography from "./../../Components/UI/Typography/Typography";
import TextFieldComp from "./../../Components/UI/Input/TextField";
import ErrorBoundary from "./../../Errors/ErrorBoundary";
import { MenuItem, Select } from "@mui/material";
import { BotsLink } from "../../config/constants";
const Main = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}));
const Button = styled(ButtonComp)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LevelModel = ({ updateHandler, selected }) => {
  const [value, setValue] = React.useState("");
  const [selectedBot, setSelectedBot] = React.useState(null);

  const categoryTitle = selected.row.title;
  const ibot = selected.row.bot;

  useEffect(() => {
    setValue(categoryTitle);
    if (ibot?.NAME === "O LEVEL") {
      setSelectedBot(BotsLink.OLEVEL);
    } else {
      setSelectedBot(BotsLink.ALEVEL);
    }
  }, [ibot, categoryTitle]);
  return (
    <Main>
      <ErrorBoundary>
        <Typography>Edit Level: </Typography>
        <TextFieldComp
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label=""
          fullWidth
        />
        <Typography sx={{ marginTop: "10px" }}>Edit AI Bot :</Typography>

        <Select
          sx={{ width: "100%" }}
          labelId="demo-simple-select-label"
          id="bots"
          value={selectedBot}
          label="bot"
          onChange={(e) => {
            setSelectedBot(e.target.value);
          }}
        >
          <MenuItem value={BotsLink.OLEVEL}>{BotsLink.OLEVEL.NAME}</MenuItem>
          <MenuItem value={BotsLink.ALEVEL}>{BotsLink.ALEVEL.NAME}</MenuItem>
        </Select>
        <ButtonWrapper>
          <Button
            onClick={(e) => {
              let data = { value, bot: selectedBot };
              updateHandler(data);
            }}
          >
            Update
          </Button>
        </ButtonWrapper>
      </ErrorBoundary>
    </Main>
  );
};

export default LevelModel;
