import { styled } from '@mui/system';

export const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ImgBox = styled('div')(() => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 430,
  width: 400,
  boxShadow: 24,
  position: 'relative',
}));

export const Image = styled('img')(() => ({
  maxHeight: 400,
  width: 400,
  objectFit: 'cover',
}));

export const ClsoeIcon = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  cursor: 'pointer',
  paddingBottom: '5px',
  paddingRight: '2px',
  position: 'absolute',
  top: 10,
  right: 10,
  alignItems: 'center',
}));
