const constants = {
  title: "Super Admin Panel",
  log_in: "Log In",
  sign_up: "Sign Up",
  subjects: [
    { name: "Physics", id: "physics" },
    { name: "Chemistry", id: "chemistry" },
    { name: "Biology", id: "biology" },
    { name: "Mathematics", id: "mathematics" },
  ],
};
export const BotsLink = {
  OLEVEL: {
    NAME: "O LEVEL",
    LINK: "https://www.chatbase.co/chatbot-iframe/-_xqd7Unl_jkApvtNgaLJ",
  },
  ALEVEL: {
    NAME: "A LEVEL",
    LINK: "https://www.chatbase.co/chatbot-iframe/oK82gkWGTcMkUsF1AF9Is",
  },
};

export const tinnyMceConst = {
  tinnyMceApiKey: "f12a2c9jan1u6dsz0h536vaxzj81d0cr2pqexvvj0qvle8t6",
  imgType: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
  filePikerType: "file image media",
  plugins:
    "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ",
  toolBar:
    "undo redo | formatselect | fullpage" +
    "bold italic backcolor | alignleft aligncenter | " +
    "| fontfamily fontsize blocks |" +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help | image ",
  contentDefaultStyle:
    "body { font-family:system-ui,Helvetica,Arial,sans-serif; font-size:15px  ;font-weight:300}",
};
// ONLY USE THIS TO ADJUST NUMBER
export const LIMIT_IN_BYTES = 300000;

const BYTE_TO_KILO_BYTE = (byteLength) => byteLength / 1000;

export const SIZE_OF_TEXT_EDITOR_INCREASED_ERROR = `Content Size limit reached! Please add content under ${BYTE_TO_KILO_BYTE(
  LIMIT_IN_BYTES
)}KB. Hint: Reduce images/videos or compress them`;

export default constants;
