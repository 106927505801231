import api from '../../services';
import { useSnackbar } from 'notistack';
import ConfigModel from './ConfigModel';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../../Components/UI/Table/Table';
import Modal from '../../Components/UI/Modal/Modal';
import EditOffIcon from '@mui/icons-material/EditOff';
import Button from '../../Components/UI/Button/Button';
import ErrorBoundary from '../../Errors/ErrorBoundary';
import Loader from '../../Components/UI/Loader/Loader';
import Stack from '../../Components/UI/Layout/Stack/Stack';
import TextFieldComp from '../../Components/UI/Input/TextField';
import Typography from '../../Components/UI/Typography/Typography';
import PromptSnackbar from '../../Components/UI/Snackbar/PromptSnackbar';

const ConceptTags = () => {
  const [addCateg, setAddCateg] = useState('');
  const [addCategModal, setAddCategModal] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [dataIsLoading, setDataIsLoading] = React.useState(false);
  const [deleteSubject, setDeleteSubject] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const isTable = true;

  const getConceptTags = async () => {
    let temp = await api.get.getConceptTags(isTable);
    setData(temp);
    setDataIsLoading(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (newState) => {
    setDeleteSubject({ open: true, ...newState });
  };

  const fields = [
    { field: 'id' },
    { field: 'value', headerName: 'Simulation Tags', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              setSelectedCategory(params);
              setOpenModel(true);
            }}
          >
            <EditOffIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            style={{ color: 'red' }}
            onClick={(e) => {
              handleClick({
                vertical: 'top',
                horizontal: 'center',
              });
              setSelectedCategory(params);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const deleteTagHandler = async () => {
    const result = await api.delete.deleteConceptTag(selectedCategory.row.id);
    const { error } = result;
    if (!error) {
      enqueueSnackbar('Simulation Tag deleted successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(
        'OOPS! error occoured while deleting Simulation Tag, Please try again',
        {
          variant: 'error',
          autoHideDuration: 4000,
        }
      );
    }
    getConceptTags();
    setDeleteSubject({ ...deleteSubject, open: false });
  };

  const saveCategoryHandler = async () => {
    const result = await api.post.addConceptTag(addCateg);

    const { error } = result;
    if (!error) {
      enqueueSnackbar('Simulation Tag created successfully!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(
        'OOPS! error occoured while creating Simulation Tag, Please try again',
        {
          variant: 'error',
          autoHideDuration: 4000,
        }
      );
    }

    getConceptTags();
    setAddCategModal(false);
    setAddCateg('');
  };

  const handleCloseModal = () => {
    setAddCategModal(false);
    setOpenModel(false);
  };

  const updateApiCall = async (e) => {
    try {
      let result = await api.put.updateConceptTag(selectedCategory.row.id, e);
      const { error } = result;
      if (error) {
        throw new Error('OOPS! error occoured while Updating Simulation Tag ');
      } else {
        getConceptTags();
        setOpenModel(false);
        enqueueSnackbar('Simulation Tag updated successfully!', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setOpenModel(false);
      enqueueSnackbar('OOPS! error occoured while updating unit', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  };

  useEffect(() => {
    setDataIsLoading(true);
    getConceptTags();
  }, []);

  return (
    <React.Fragment>
      <Stack>
        <Button onClick={(e) => setAddCategModal(true)} width="10rem">
          Add New Simulation Tag
        </Button>
        <ErrorBoundary>
          {dataIsLoading ? (
            <Loader />
          ) : (
            <Table tableData={data} cols={fields} />
          )}
        </ErrorBoundary>
      </Stack>
      <Modal
        title="Add Simulation Tag"
        open={addCategModal}
        handleClose={handleCloseModal}
      >
        <Stack>
          <Typography variant="h6">Add Simulation Tag</Typography>
          <TextFieldComp
            value={addCateg}
            onChange={(e) => setAddCateg(e.target.value)}
            label="Add Simulation Tag name"
          />
          <Button
            onClick={saveCategoryHandler}
            style={{ alignSelf: 'center', width: '12rem' }}
          >
            Save Simulation Tag
          </Button>
        </Stack>
      </Modal>
      <Modal open={openModel} handleClose={handleCloseModal}>
        <ConfigModel
          updateHandler={(e) => updateApiCall(e)}
          selectedCategory={selectedCategory}
          title={'Edit Simulation Tag'}
        />
      </Modal>
      <PromptSnackbar
        setState={setDeleteSubject}
        state={deleteSubject}
        clickHandler={deleteTagHandler}
      >
        Do you want to Delete this Simulation tag
      </PromptSnackbar>
    </React.Fragment>
  );
};

export default ConceptTags;
