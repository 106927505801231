import React from "react";
import * as Steps from "./Steps/";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Grid } from "@mui/material";

import { BiExit, BiNote, BiNotepad, BiSidebar } from "react-icons/bi";
import { createCourseStepperActions } from "../../redux/reducers/createCourseStepper";
import api from "../../services";
import flash from "../../assets/img/svgs/Flash.svg";
import algorith from "../../assets/img/svgs/algorithm.svg";
import notes from "../../assets/img/svgs/notes.svg";
import paper from "../../assets/img/svgs/Paper.svg";
import unit from "../../assets/img/svgs/Units.svg";
import vector from "../../assets/img/svgs/Vector.svg";
import question from "../../assets/img/svgs/question.svg";
import simulation from "../../assets/img/svgs/Simulation.svg";
import ButtonComp from "../../Components/UI/Button/Button";
import { useNavigate } from "react-router-dom";

const CreateCourse = () => {
  const page = useSelector((state) => state.createCourseStepper);
  const [hasUnits, setHasUnits] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(page);
  const dispatch = useDispatch();
  const courseId = useSelector((state) => state.createCourse.currentCourdId);
  // const navigate = useNavigate();
  // const confirm = async () => {
  //   let published = await api.put.publishedApiHadler(courseId);
  //   if (published.error === false) {
  //     // dispatch(createCourseStepperActions.setPage(7));
  //     setTimeout(() => {
  //       dispatch(createCourseStepperActions.resetPage());
  //       navigate("../courses", { replace: true });
  //     }, 500);
  //   } else {
  //     console.log("publish api not working");
  //   }
  // }

  let tabs = [
    {
      name: "Add Units",
      value: "add units",
      icon: unit,
      page: 2,
    },
    {
      name: "Add Chapters",
      value: "add chapters",

      icon: paper,
      page: hasUnits ? 3 : 2,
    },
    {
      name: "Add Snacks",
      value: "add snacks",
      page: 4,
      icon: algorith,
    },
    {
      name: "Add Learning Objectives",
      value: "add learning objecives",
      icon: vector,
      page: 5,
    },

    {
      name: "Add Notes",
      value: "add notes",
      icon: notes,
      page: activeTab === "add notes" ? 6 : 6,
    },
    {
      name: "Add FlashCards",
      value: "add flashcards",
      icon: flash,
      page: activeTab === "add flashcards" ? 7 : 7,
    },

    {
      name: "Add Simulations",
      value: "add simulations",
      icon: simulation,
      page: activeTab === "add simulations" ? 8 : 8,
    },

    {
      name: "Add Questions",
      value: "add questions",
      icon: question,
      page: 9,
    },
  ];
  return (
    <React.Fragment>
      {page !== 1 && page !== 10 && (
        <Container style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container>
            {tabs.map((tab) => {
              return (
                <Grid item style={{ marginBottom: "5px" }}>
                  {!hasUnits && tab.value === "add units" ? (
                    ""
                  ) : (
                    <Container
                      onClick={() => {
                        setActiveTab(tab.value);

                        if (tab.value === "add units") {
                          dispatch(createCourseStepperActions.setPage(2));
                        }
                        if (tab.value === "add chapters") {
                          if (!hasUnits) {
                            dispatch(createCourseStepperActions.setPage(2));
                          } else {
                            dispatch(createCourseStepperActions.setPage(3));
                          }
                        }
                        if (tab.value === "add snacks") {
                          dispatch(createCourseStepperActions.setPage(4));
                        }
                        if (tab.value === "add learning objecives") {
                          dispatch(createCourseStepperActions.setPage(5));
                        }
                        if (tab.value === "add notes") {
                          dispatch(createCourseStepperActions.setPage(6));
                        }
                        if (tab.value === "add flashcards") {
                          dispatch(createCourseStepperActions.setPage(7));
                        }
                        if (tab.value === "add simulations") {
                          dispatch(createCourseStepperActions.setPage(8));
                        }
                        if (tab.value === "add questions") {
                          dispatch(createCourseStepperActions.setPage(9));
                        }
                      }}
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        height: "40px",
                        color: `${tab.page === page ? "#624BA2" : "#5C516C"}  `,

                        backgroundColor: `${
                          tab.page === page
                            ? "rgba(204, 204, 221, 0.25)"
                            : "transparent"
                        }`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={tab.icon} />
                      {tab.name}
                    </Container>
                  )}
                </Grid>
              );
            })}
          </Grid>
          {/* <ButtonComp onClick={confirm} style={{ marginLeft: '10px', height: '40px' }} >
          Save
        </ButtonComp> */}
        </Container>
      )}
      {(page === 1 && (
        <Steps.ConfigureCourse
          hasUnits={hasUnits}
          courseHasUnits={(e) => setHasUnits(e)}
          getHasUnits={(e) => setHasUnits(e)}
        />
      )) ||
        (hasUnits
          ? (page === 2 && <Steps.AddUnits />) ||
            (page === 3 && <Steps.AddChaptersWithUnits />)
          : page === 2 && <Steps.AddChaptersWithoutUnits />) ||
        (page === 4 && <Steps.AddSnacks />) ||
        (page === 5 && <Steps.AddLearningObjectives />) ||
        (page === 6 && (
          <Steps.AddSnackFlashCards isnotes={true} isSimulations={false} />
        )) ||
        (page === 7 && (
          <Steps.AddSnackFlashCards isnotes={false} isSimulations={false} />
        )) ||
        (page === 8 && (
          <Steps.AddSnackFlashCards isnotes={false} isSimulations={true} />
        )) ||
        (page === 9 && <Steps.AddPastPapers />) || <Steps.CompleteForm />}
    </React.Fragment>
  );
};

export default CreateCourse;

/**
 * 
 (page === 4 && <Steps.AddSnacks />) ||
            (page === 5 && <Steps.AddLearningObjectives />) ||
            (page === 6 && <Steps.AddSnackFlashCards />)
 */
